/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import ButtonAnalog from "./Depricated/Buttons/ButtonAnalog";
import React from "react";
import { useNavigate } from "react-router";
import { theme } from "./theme";
import AnalogButton from "./Depricated/Buttons/AnalogButton";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    font-size: 16px;
    color: #fff;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    align-items: center;
    strong {
      user-select: text;
    }
    span {
      user-select: text;
    }
    strong {
      color: ${theme.colors.backZ};
    }
  `,
  headerText: css`
    color: #fff;
    font-size: 20px;
  `,
  headerSubText: css`
    font-size: 16px;
  `,
  header: css`
    display: flex;
    height: 40px;
    width: 100%;
    flex-direction: column;
  `,
  buttonContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  `,
  link: css`
    text-decoration: underline;
    color: ${theme.colors.frontZ};
    cursor: pointer;
  `,
  blur: css`
    color: ${theme.colors.backZ};
  `,
  componentBox: css`
    display: flex;
    width: 300px;
    height: 350px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  `,
  subtitle: css`
    color: #fff;
    font-size: 14px;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  `,
  card: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  button: css`
    width: 325px;
    align-self: center;
  `,
};

const URL = "https://cks-web-prod.azurewebsites.net/";
const CKS: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.card}>
        <h1>My current project: Champions Keep Score</h1>
        <div>
          <span>The button below will take you to my current personal project, Champions Keep Score.</span>
          <br />
          <span>
            CKS is a Brazilian Jiu-Jitsu journal and tracking app that is currently in beta testing. Feel free to poke around!
          </span>
          <br />
          <span>Some key features I'm most proud of are:</span>
          <ul>
            <li>Rich text editor</li>
            <li>YouTube note taker full of helpful features</li>
            <ul>
              <li>Custom video player</li>
              <li>Progress bar with 'snappy' nodes for scrolling to key moments</li>
              <li>Hashtags create custom scroll points for the notepad</li>
              <li>Timestamped notes that jump to key moments in videos</li>
            </ul>
            <li>An intelligent tagging system that allows for user customization</li>
            <li>OAuth 2.0 integration</li>
            <li>Customizable journals</li>
          </ul>
          <span>Use the following credentials when you log in: </span>
          <br />
          <span>
            email: <strong>guest@championskeepscore.com</strong>
          </span>
          <br />
          <span>
            password: <strong>guest</strong>
          </span>
        </div>
        <AnalogButton
          css={styles.button}
          color={theme.colors.frontZ}
          onClick={() => window.open(URL, "_blank")}
        >
          Go to Champions Keep Score!
        </AnalogButton>
      </div>
    </div>
  );
};
export default CKS;
